body {
  background-color: #fdef7f;
  maxwidth: 25rem;
  margin: 0;
  font-family: 'Oswald';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: black;
}
